import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Flex } from 'antd';
const AddUser = () => {
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  return (

    <>
    <div className='flex flex-col items-center  gap-y-10'>
        <p className='text-2xl font-bold'>
            Add User
        </p>
        <Form
      name="login"
      initialValues={{
        remember: true,
      }}
      style={{
        maxWidth: 360,
      }}
      onFinish={onFinish}
      layout="vertical"
      className='w-full'
    >
      <Form.Item
        name="username"
        label="Username"
        rules={[
            {
              required: true,
              message: 'Please input your Username!',
            },
          ]}
      >
        <Input size="large"  placeholder="Username" />
      </Form.Item>

      <Form.Item
        name="firstname"
        label="Firstname"
        rules={[
            {
              required: true,
              message: 'Please input your Firstname!',
            },
          ]}
      >
        <Input size="large"  placeholder="Firstname" />
      </Form.Item>

      <Form.Item
        name="lastname"
        label="Lastname"
        rules={[
            {
              required: true,
              message: 'Please input your Lastname!',
            },
          ]}
      >
        <Input size="large"  placeholder="Lastname" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
            {
              required: true,
              message: 'Please input your Email!',
            },
          ]}
      >
        <Input size="large"  placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
      >
        <Input size="large"  placeholder="Password" />
      </Form.Item>
    

      <Form.Item>
        <Button block type="primary" htmlType="submit">
          Save
        </Button>
       
      </Form.Item>
    </Form>
    </div>
    </>
  );
};
export default AddUser;