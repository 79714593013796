import React, { useEffect, useState } from 'react';
import { Button, Space, Table, Tag, Input, Modal, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import axiosInstance from '../../util/axiosConfig'; // Import the axios instance

const UserList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/user-list'); // Use axiosInstance here
        setData(response.data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
        message.error('Failed to load users');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleUpdatePassword = async () => {
    try {
      await axiosInstance.put(`/${currentUserId}/update-password`, { newPassword }); // Use axiosInstance here
      message.success('Password updated successfully');
      setIsModalVisible(false);
      setNewPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
      message.error('Failed to update password');
    }
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Password Status',
      dataIndex: 'isPasswordSet',
      key: 'isPasswordSet',
    },
    {
      title: 'Time Left',
      dataIndex: 'timeLeft',
      key: 'timeLeft',
    },
    {
      title: 'Status',
      dataIndex: 'isExpired',
      key: 'isExpired',
      render: (_, { isExpired }) => {
        let color = isExpired === "Active" ? 'green' : 'red';
        return (
          <Tag color={color} key={isExpired}>
            {isExpired.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              onClick={() => {
                setCurrentUserId(record.id); // Ensure 'id' exists in your data
                setIsModalVisible(true);
              }}
              className='text-blue-600'
            />
          </Space>
        );
      },
    }
  ];

  return (
    <>
      <div className='w-full mb-5 flex justify-start'>
       
      </div>
      <Table
        pagination={{ pageSize: 6 }}
        columns={columns}
        dataSource={data}
        loading={loading}
      />
      <Modal
        title="Update Password"
        visible={isModalVisible}
        onOk={handleUpdatePassword}
        onCancel={() => setIsModalVisible(false)}
      >
        <Input.Password
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default UserList;
