import React from 'react';
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  HomeOutlined,
  PlusCircleOutlined ,
  UploadOutlined,
  UserOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu, theme } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;

const siderStyle = {
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarColor: 'unset',
};

const page = ["dashboard" , "Add-User" , "users"]; // Make sure these match your routes

const items = [
  HomeOutlined,
  PlusCircleOutlined,
  UserOutlined,

].map((icon, index) => ({
  key: String(index + 1),
  icon: React.createElement(icon),
  label: (
    <Link to={`/${page[index]}`}> {/* Proper template literals */}
      {page[index].charAt(0).toUpperCase() + page[index].slice(1)} {/* Capitalize first letter */}
    </Link>
  ),
}));

const Main = ({ children }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  
  const location = useLocation();
  
  // Find the current selected key based on location.pathname
  const currentKey = String(page.findIndex(p => location.pathname.includes(p)) + 1);

  return (
    <Layout hasSider>
      <Sider style={siderStyle}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          className="mt-16"
          selectedKeys={[currentKey]} // Dynamically set selected key
          defaultSelectedKeys={['1']} 
          items={items}
          style={{
            '--ant-menu-item-active-bg': 'transparent',
            '--ant-menu-item-selected-bg': 'transparent',
            '--ant-menu-item-selected-text-color': 'blue',
          }}
        />
    
      </Sider>
      <Layout
        style={{
          marginInlineStart: 200,
        }}
      >
        <Header className="sticky top-0 flex items-center justify-center bg-[#001529] px-10">
      
          <p className="text-white text-xl font-semibold">Admin</p>
    
        </Header>
        <Content
          style={{
            margin: '24px 16px 0',
            overflow: 'initial',
          }}
          className=" max-h-[40rem]"
        >
          <div
            style={{
              padding: 24,
              textAlign: 'center',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
            className="h-full"
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }} className="mt-auto">
          NikMention ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Main;
