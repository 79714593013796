import React, { useEffect, useState } from 'react';
import { Button, Table, Tag, Modal, message, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import axiosInstance from '../../util/axiosConfig';
import moment from 'moment';

const { RangePicker } = DatePicker;

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null); // Store selected ticket details
  const [filteredInfo, setFilteredInfo] = useState({});
  const [dates, setDates] = useState(null); // Store selected date range

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/tickets');
      setData(response.data);
    } catch (error) {
      message.error('Failed to load tickets');
    }
    setLoading(false);
  };

  const handleResolve = async () => {
    try {
      await axiosInstance.put(`/resolved/${selectedTicket.id}`, { status: 'Resolved' });
      message.success('Ticket resolved successfully');
      setSelectedTicket(null); // Close the modal after resolving
      fetchTickets(); // Reload tickets after resolving
    } catch (error) {
      message.error('Failed to resolve ticket');
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const handleTableChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const handleDateFilterChange = (dates, dateStrings) => {
    setDates(dateStrings);
    const filteredData = data.filter(ticket => {
      const ticketDate = moment(ticket.createdAt);
      return ticketDate.isBetween(dateStrings[0], dateStrings[1], null, '[]');
    });
    setData(filteredData);
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Time Left',
      dataIndex: 'timeLeft',
      key: 'timeLeft',
      render: (timeLeft, record) => (
        <span style={{ color: record.isExpired === 'Expired' ? 'red' : 'green' }}>
          {timeLeft}
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Pending', value: 'Pending' },
        { text: 'Resolved', value: 'Resolved' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.toLowerCase() === value.toLowerCase(),
      render: (_, { status }) => {
        let color = status === 'Pending' ? 'orange' : 'green';
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <span>{createdAt}</span>, // Display relative time
     
      
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button type="link" onClick={() => setSelectedTicket(record)}>
          Resolve
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className='w-full mb-5 flex justify-start'>
      
      </div>
      <Table
        pagination={{ pageSize: 6 }}
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="_id"
        onChange={handleTableChange}
      />
      
      <Modal
        title="Resolve Ticket"
        visible={!!selectedTicket}
        onCancel={() => setSelectedTicket(null)}
        onOk={handleResolve}
      >
        {selectedTicket ? (
          <div>
            <p><strong>Email:</strong> {selectedTicket.email}</p>
            <p><strong>Category:</strong> {selectedTicket.category}</p>
            <p><strong>Message:</strong> {selectedTicket.message}</p>
            <p><strong>Telegram:</strong> {selectedTicket.telegram}</p>
            <p><strong>Status:</strong> {selectedTicket.status}</p>
            <p><strong>Time Left:</strong> {selectedTicket.timeLeft}</p>
            <p><strong>Created At:</strong> {moment(selectedTicket.createdAt).format('LLL')}</p>
          </div>
        ) : (
          <p>Loading ticket details...</p>
        )}
      </Modal>
    </>
  );
};

export default Dashboard;
