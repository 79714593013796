import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Login from "./components/pages/Login";
import Dashbord from "./components/pages/Dashbord";
import Main from "./components/UI/Layout";
import Ticket from "./components/pages/Ticket";
import AddUser from "./components/pages/AddUser";
import UserList from "./components/pages/userList";
import AuthGuard from './components/AuthGuard'; // Import AuthGuard

const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
    },
    {
        path: "/dashboard",
        element: (
            <AuthGuard>
                <Main><Dashbord /></Main>
            </AuthGuard>
        ),
    },
    {
        path: "/check-ticket/:id",
        element: (
            <AuthGuard>
                <Main><Ticket /></Main>
            </AuthGuard>
        ),
    },
    {
        path: "/Add-user",
        element: (
            <AuthGuard>
                <Main><AddUser /></Main>
            </AuthGuard>
        ),
    },
    {
        path: "/users",
        element: (
            <AuthGuard>
                <Main><UserList /></Main>
            </AuthGuard>
        ),
    },
    {
        path: "/*",
        element: <Navigate to="/" />
    }
]);

export default router;
