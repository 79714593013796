import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { Link, useParams } from "react-router-dom";
const { TextArea } = Input;
const { Option } = Select;

const Ticket = () => {
    const { id } = useParams();
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };

  return (
    <div className='flex flex-col w-full  items-center  gap-y-10'>
      <p className='text-2xl font-bold'>Open Ticket</p>
      <Form
        name="open_ticket"
        initialValues={{
          username: 'user123', // Replace with actual username
          email: 'user@example.com', // Replace with actual email
        }}
        style={{ maxWidth: 460 }}
        onFinish={onFinish}
        layout="vertical"
        className='w-full '
      >
        <Form.Item
          name="username"
          label="Username"
        
        >
          <Input size="large" placeholder="Username" disabled />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
       
        >
          <Input size="large" placeholder="Email" disabled />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          
        >
          <TextArea size="large" className='max-h-[80px]' placeholder="Graphics Link..." />
        </Form.Item>

        <Form.Item
          name="category"
          label="Category"
         
        >
          <Select size="large" placeholder="Select a category">
            <Option value="freepik">FreePik</Option>
            <Option value="envato">Envato</Option>
            <Option value="flaticon">Flaticon</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="telegram"
          label="Telegram"
       
        >
          <Input size="large" placeholder="telegram" disabled />
        </Form.Item>

        <Button block type="primary" htmlType="submit">
            Resolved
          </Button>
      </Form>
    </div>
  );
};

export default Ticket;
