import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Flex, message } from 'antd';
import axios from '../../util/axiosConfig';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Login = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const onFinish = async (values) => {
    try {
      const response = await axios.post('/login', {
        email: values.username, // Assuming username is the email
        password: values.password,
      });

      const { token } = response.data;
      localStorage.setItem('jwt', token); // Store the token in local storage

      message.success('Login successful!');
      console.log('Received token: ', token);
      
      // Redirect to the admin dashboard
      navigate('/dashboard'); // Navigate to the dashboard
    } catch (error) {
      console.error('Login error:', error);
      message.error('Login failed! Please check your credentials.');
    }
  };

  return (
    <div className='w-screen h-screen flex justify-center items-center bg-[#EEF7FF]'>
      <Form
        name="login"
        initialValues={{
          remember: true,
        }}
        style={{
          maxWidth: 360,
        }}
        onFinish={onFinish}
        className='w-[25rem] p-9 border-2 rounded-lg bg-white'
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Flex justify="space-between" align="center">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <a href="">Forgot password</a>
          </Flex>
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
